import React from 'react';
import 'src/Styles/sass/home.scss'


function NotFound(props){

    
    
    return (
    <div className='home-dashboard'>
        <div className='not-found'>
            <h1>404</h1>
            <span>چنین صفحه ای یافت نشد</span>
        </div>
    </div>
    )
}

export default NotFound;

